<template>
    <div :class="[comingSoonWidthClass, 'social-inner']">
        <div>
            <router-link :to="{ name: 'coming-soon' }"><img src="/assets/icons/icon-social-discord.svg" alt="discord" /></router-link>
        </div>
        <div>
            <router-link :to="{ name: 'coming-soon' }"><img src="/assets/icons/icon-social-twitter.svg" alt="twitter" /></router-link>
        </div>
        <div v-if="notComingSoonView">
            <router-link :to="{ name: 'coming-soon' }"><img src="/assets/icons/icon-social-instagram.svg" alt="instagram" /></router-link>
        </div>
    </div>
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const notComingSoonView = computed(() => route.name !== "coming-soon");
const comingSoonWidthClass = computed(() => (route.name === "coming-soon" ? "social-inner-width" : ""));
</script>

<style lang="scss" scoped>
.social-inner {
    display: flex;
    flex-flow: row;
    width: 95px;
    flex-wrap: nowrap;
    justify-content: space-between;

    img {
        color: #fff;
    }
}
.social-inner-width {
    width: 75px !important;

    @media (min-width: 600px) {
        padding-top: 20px;
        width: 105px !important;
    }
}
</style>
