<template>
    <loader v-if="isLoading" />
    <navbar />
    <floating-navbar />
    <router-view />
    <app-footer v-if="notComingSoonView" />
</template>

<script setup>
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { computed, onMounted } from "vue";

import Loader from "./components/layout/Loader";
import Navbar from "./components/layout/navbar/Navbar";
import AppFooter from "./components/layout/footer/Footer";
import FloatingNavbar from "./components/layout/navbar/components/FloatingNavbar";

const store = useStore();
const isLoading = computed(() => store.state.isLoading);

onMounted(() => {
    document.title = "Diamonds";
    if (!store.state.ethPrice) store.dispatch("getEthPrice");
    store.dispatch("setIsLoading", false);
});

const route = useRoute();
const notComingSoonView = computed(() => route.name !== "coming-soon");
const isFloatingOpen = computed(() => store.state.isFloatingNavOpen);
</script>

<style lang="scss" scoped>
#app {
    overflow: hidden;
}
</style>
