<template>
    <div class="q-pa-md loader-wrapper">
        <q-spinner color="light" size="3em" />
    </div>
</template>

<script>
export default {
    name: "Loader",
};
</script>

<style lang="scss" scoped>
.loader-wrapper {
    color: #fff;
    z-index: 9999;
    opacity: 0.55;
    display: flex;
    position: fixed;
    min-width: 100vw;
    min-height: 100vh;
    background-color: black;

    .q-spinner {
        margin: auto;
    }
}
</style>
