import { createRouter, createWebHistory } from "vue-router";

const routes = [
    {
        path: "/",
        name: "home",
        component: () => import("../views/home/Home.vue"),
    },
    {
        path: "/diamond-search",
        name: "products",
        component: () => import("../views/products/Products.vue"),
    },
    {
        path: "/product/:lot",
        name: "product",
        component: () => import("../views/product/Product.vue"),
    },
    {
        path: "/quality",
        name: "quality",
        component: () => import("../views/quality-scores/QualityScores.vue"),
    },
    {
        path: "/quality/:parameter",
        name: "sub-quality",
        component: () => import("../views/quality-scores/QualityScores.vue"),
    },
    {
        path: "/coming-soon",
        name: "coming-soon",
        component: () => import("../components/layout/ComingSoon.vue"),
    },
    {
        path: "/privacy-policy",
        name: "privacy-policy",
        component: () => import("../views/privacy-policy/PrivacyPolicy.vue"),
    },
    {
        path: "/terms-of-use",
        name: "terms-of-use",
        component: () => import("../views/terms-of-use/TermsOfUse.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior: function (to, _from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return { el: to.hash, behavior: "smooth" };
        } else {
            // scroll top when route changed
            window.scrollTo(0, 0);
        }
    },
});

export default router;
