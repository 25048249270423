<template>
    <div class="icons-wrapper">
        <transition name="slide-fade">
            <p v-if="!show">
                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 21v-2h20v2H2zm0-8v-2h18v2H2zm0-8V3h20v2H2z" fill="#FFF" fill-rule="evenodd" />
                </svg>
            </p>
        </transition>
        <transition name="slide-fade">
            <p v-if="show">
                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="m19.39 5.959-1.344-1.344L12 10.66 5.954 4.615 4.611 5.96l6.046 6.044-6.046 6.047 1.343 1.344L12 13.347l6.046 6.047 1.343-1.344-6.046-6.046z"
                        fill="#FFF"
                        fill-rule="evenodd" />
                </svg>
            </p>
        </transition>
    </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

const show = computed(() => store.state.isFloatingNavOpen);
</script>

<style lang="scss" scoped>
.icons-wrapper {
    position: relative;
    display: flex;
    flex-flow: row;

    svg {
        opacity: 1;
        cursor: pointer;
        position: absolute;
        transition: 0.25s all;

        &:hover {
            opacity: 0.75;
        }
    }
}
.slide-fade-enter-active {
    transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-leave-active {
    transition: all 0.25s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter {
    opacity: 0;
    height: 0;
    transform: translateX(-3px);
}
.slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    height: 0;
    transform: translateX(3px);
}
</style>
