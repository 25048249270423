export const isString = (str) => {
    return str !== "" && String(str) ? String(str) : null;
};

export const isEmail = (email) => {
    const notEmpty = isString(email);
    const notTooShort = notEmpty && notEmpty.length > 9;
    const containEmailSigns = notEmpty !== null && notEmpty.includes("@") && notEmpty.includes(".");

    return notEmpty && notTooShort && containEmailSigns
        ? email
        : { error: !notEmpty ? "Email cannot be empty." : !containEmailSigns ? "Email is not valid." : "Email is too short." };
};

export const isUsername = (user) => {
    const notEmpty = isString(user);
    const notTooShort = notEmpty && notEmpty.length > 2;

    return notEmpty && notTooShort ? user : { error: !notEmpty ? "Username cannot be empty." : "Username too short." };
};

export const isPassword = (pass) => {
    const notEmpty = isString(pass);
    return notEmpty ? pass : { error: !notEmpty ? "Password cannot be empty." : "Invalid username or password" };
};
