import axios from "axios";
import { createStore } from "vuex";
import { isEmail } from "../validator";
import { getQueryStringFromObj } from "../helpers";

export default createStore({
    state: {
        namespaced: true,
        baseUrl: "/",
        baseApiUrl: "/api",
        // baseUrl: "http://127.0.0.1:8000/",
        // baseApiUrl: "http://127.0.0.1:8000/api",

        isLoading: true,
        isFloatingNavOpen: false,
        ethPrice: null,
        filters: {
            selectedShapes: [],
            shapes: ["round", "emerald", "radiant", "cushion", "marquise", "pear", "oval", "asscher", "princess", "other"],
            updateShape: 1,

            minratio: 0.0,
            maxratio: 10,
            minmaxratio: { min: 0.0, max: 10 },
            updateRatio: 3,

            mininclusions: 0,
            maxinclusions: 99,
            minmaxinclusions: { min: 0, max: 99 },
            updateInclusions: 3,

            mincarat: 0.25,
            maxcarat: 10.0,
            minmaxcarat: { min: 0.25, max: 10.0 },
            updateCarat: 3,

            currency: "ETH",
            currencies: ["ETH", "USD"],
            minprice: 0.0001,
            maxprice: 1000,
            minmaxprice: {
                ETH: { min: 0.0001, max: 1000, step: 0.001 },
                USD: { min: 130, max: 1000000, step: 20 },
            },
            updatePrice: 3,

            minquality: 0,
            maxquality: 10,
            quality: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            mincut: "excellent",
            maxcut: "poor",
            cut: ["excellent", "very good", "good", "fair", "poor"],
            minclarity: "fl",
            maxclarity: "i3",
            clarity: ["fl", "if", "vvs1", "vvs2", "vs1", "vs2", "si1", "si2", "i2", "i3"],
            mincolor: "d",
            maxcolor: "fancy",
            color: ["d", "e", "f", "g", "h", "i", "j", "k", "l-r", "s-z", "fancy"],
            minpolish: "excellent",
            maxpolish: "poor",
            polish: ["excellent", "very good", "good", "fair", "poor"],
            minsymmetry: "excellent",
            maxsymmetry: "poor",
            symmetry: ["excellent", "very good", "good", "fair", "poor"],
            mingirdle: "e. thin",
            maxgirdle: "e. thick",
            girdle: ["e. thin", "v. thin", "thin", "medium", "s. thick", "thick", "v. thick", "e. thick"],
            minculet: "none",
            maxculet: "e. large",
            culet: ["none", "v. small", "s. medium", "s. large", "large", "v. large", "e. large"],
            minflourescent: "none",
            maxflourescent: "very strong",
            flourescent: ["none", "faint", "medium", "strong", "very strong"],
        },
        productData: {},
        productsData: [],
        productsTotalPages: 1,
    },
    mutations: {
        SET_IS_LOADING(state, isLoading) {
            state.isLoading = isLoading;
        },
        SET_IS_FLOATING_NAV_OPEN(state, isFloatingNavOpen) {
            state.isFloatingNavOpen = isFloatingNavOpen;
        },
        SET_PRODUCT_DATA(state, productData) {
            state.productData = productData;
        },
        SET_PRODUCTS_DATA(state, productsData) {
            state.productsData = productsData;
        },
        SET_FILTERS(state, filters) {
            state.filters = filters;
        },
        SET_PRODUCTS_TOTAL_PAGES(state, productsTotalPages) {
            state.productsTotalPages = productsTotalPages;
        },
        SET_ETH_PRICE(state, ethPrice) {
            state.ethPrice = ethPrice;
        },
    },
    actions: {
        setIsLoading({ commit }, isLoading) {
            commit("SET_IS_LOADING", isLoading);
        },
        setIsFloatingNavOpen({ commit }, isFloatingNavOpen) {
            commit("SET_IS_FLOATING_NAV_OPEN", isFloatingNavOpen);
        },
        setFilters({ commit }, filters) {
            commit("SET_FILTERS", filters);
        },
        setFiltersOnRouteQuery({ dispatch, state }, payload) {
            if (payload.router) {
                const query = payload.route.query ? { ...payload.route.query } : {};

                const filters = ["cut", "clarity", "color", "polish", "symmetry", "girdle", "culet", "flourescent"];
                filters.forEach((filter) => {
                    const minFilter = `min${filter}`;
                    const maxFilter = `max${filter}`;

                    if (state.filters[filter].indexOf(state.filters[minFilter]) !== 0) query[minFilter] = state.filters[minFilter];
                    else if (query[minFilter]) delete query[minFilter];
                    if (state.filters[filter].indexOf(state.filters[maxFilter]) < state.filters[filter].length - 1) query[maxFilter] = state.filters[maxFilter];
                    else if (query[maxFilter]) delete query[maxFilter];
                });

                if (state.filters.minquality > 1) query.minquality = state.filters.minquality;
                else if (query.minquality) delete query.minquality;
                if (state.filters.maxquality !== 10) query.maxquality = state.filters.maxquality;
                else if (query.maxquality) delete query.maxquality;
                if (state.filters.minquality > state.filters.maxquality) {
                    query.minquality = state.filters.minquality - 0.5;
                    query.maxquality = state.filters.minquality - 0.5;
                }

                if (state.filters.selectedShapes.length > 0) {
                    const selectedShapes = state.filters.selectedShapes;
                    query.shape = "";
                    for (let key in selectedShapes) {
                        if (Number(key) + 1 === selectedShapes.length) query.shape += selectedShapes[key];
                        else query.shape += selectedShapes[key] + ",";
                    }
                } else if (query.shape) delete query.shape;

                const issetMin = state.filters.minprice > state.filters.minmaxprice[state.filters.currency].min;
                const issetMax = state.filters.maxprice < state.filters.minmaxprice[state.filters.currency].max;

                if (issetMin || issetMax) query.currency = state.filters.currency.toLowerCase();
                if (!issetMin && !issetMax && query.currency) delete query.currency;

                if (issetMin) query.minprice = state.filters.minprice;
                else if (query.minprice) delete query.minprice;

                if (issetMax) query.maxprice = state.filters.maxprice;
                else if (query.maxprice) delete query.maxprice;

                if (state.filters.mincarat > state.filters.minmaxcarat.min && Number(query.mincarat) !== state.filters.mincarat)
                    query.mincarat = state.filters.mincarat;
                else if (query.mincarat) delete query.mincarat;
                if (state.filters.maxcarat < state.filters.minmaxcarat.max) query.maxcarat = state.filters.maxcarat;
                else if (query.maxcarat) delete query.maxcarat;

                const otherFilters = ["ratio", "inclusions"];

                otherFilters.forEach((filter) => {
                    if (state.filters[`min${filter}`] > state.filters[`minmax${filter}`].min) query[`min${filter}`] = state.filters[`min${filter}`];
                    else if (query[`min${filter}`]) delete query[`min${filter}`];

                    if (state.filters[`max${filter}`] < state.filters[`minmax${filter}`].max) query[`max${filter}`] = state.filters[`max${filter}`];
                    else if (query[`max${filter}`]) delete query[`max${filter}`];
                });

                payload.router.push({ name: "products", query: query });
                dispatch("getProductsData", { router: { ...payload.router }, query: { ...query } });
            }
        },
        resetFilters({ commit, dispatch, state }, payload) {
            const filters = { ...state.filters };

            const filtersForEach = ["cut", "clarity", "quality", "color", "polish", "symmetry", "girdle", "culet", "flourescent"];

            filtersForEach.forEach((filter) => {
                filters[`min${filter}`] = filters[filter][0];
                filters[`max${filter}`] = filters[filter][filters[filter].length - 1];
            });

            filters.selectedShapes = [];

            filters.currency = state.filters.currencies[0];
            filters.minprice = state.filters.minmaxprice[filters.currency].min;
            filters.maxprice = state.filters.minmaxprice[filters.currency].max;

            filters.mincarat = state.filters.minmaxcarat.min;
            filters.maxcarat = state.filters.minmaxcarat.max;

            const otherFilters = ["ratio", "inclusions"];
            otherFilters.forEach((filter) => {
                filters[`min${filter}`] = filters[`minmax${filter}`].min;
                filters[`max${filter}`] = filters[`minmax${filter}`].max;
            });

            commit("SET_FILTERS", filters);

            if (payload.resetPage) payload.router.push({ name: "products" });
            else payload.router.push({ name: "products", query: { page: payload.query?.page } });

            dispatch("getProductsData", { router: { ...payload.router }, query: { page: payload.query?.page } });
        },
        sendNewSubscriptionEmail({ commit, dispatch, state }, payload) {
            commit("SET_IS_LOADING", true);

            const validEmail = isEmail(payload.email);
            if (typeof validEmail !== "string") payload.router.push({ name: "coming-soon", params: { type: "failure", message: "Not valid email." } });

            axios
                .post(`${state.baseApiUrl}/subscribers`, { email: payload.email })
                .then((response) => {
                    if (response.data.success) payload.router.push({ name: "coming-soon", params: { type: "success", message: response.data.message } });
                })
                .catch((e) => {
                    if (e && e.response && e.response.data && e.response.data.message && e.response.status !== 500)
                        payload.router.push({ name: "coming-soon", params: { type: "failure", message: e.response.data.message } });
                })
                .finally(() => {
                    commit("SET_IS_LOADING", false);
                });
        },
        setProductData({ commit, state }, payload) {
            commit("SET_IS_LOADING", true);

            // Get product data by axios request.
            axios
                .post(`${state.baseApiUrl}/product`, { lot: payload.lot })
                .then((response) => {
                    if (response.data.length > 0) commit("SET_PRODUCT_DATA", response.data[0]);
                    else payload.router.push({ name: "home" });
                })
                .catch((e) => {
                    payload.router.push({ name: "home" });
                })
                .finally(() => {
                    commit("SET_IS_LOADING", false);
                });
        },
        getProductsData({ commit, state }, payload) {
            commit("SET_IS_LOADING", true);
            const routeQuery = payload.query ? getQueryStringFromObj(payload.query) : "";

            // Get product data by axios request.
            axios
                .get(`${state.baseApiUrl}/products` + routeQuery)
                .then((response) => {
                    commit("SET_PRODUCTS_DATA", response.data.data);
                    commit("SET_PRODUCTS_TOTAL_PAGES", response.data.totalPages);
                })
                .catch((e) => {
                    payload.router.push({ name: "home" });
                })
                .finally(() => {
                    commit("SET_IS_LOADING", false);
                });
        },
        getEthPrice({ commit, state }) {
            axios.get(`${state.baseApiUrl}/crypto/eth`).then((response) => {
                if (response && response.data && response.data) commit("SET_ETH_PRICE", response.data);
            });
        },
    },
    modules: {},
});
