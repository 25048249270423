<template>
    <div :class="['mobile-navbar-wrapper', notComingSoonViewConnectClass]">
        <hamburger class="hamburger-wrapper" v-if="notComingSoonView" @click="toggleNavbar" />
        <mobile-logo :class="[notComingSoonViewLogoClass]" @click="closeNavbar" />
        <div class="login-btn-wrapper btn-secondary" @click="animateRipple" ref="mvBtn">
            <router-link :to="{ name: 'coming-soon' }">
                Connect
                <transition-group>
                    <span
                        class="ripple"
                        :ref="'ripple-' + i"
                        :key="'ripple' + i"
                        v-for="(val, i) in ripples"
                        :style="{ top: val.y + 'px', left: val.x + 'px' }"
                        v-on:animationend="rippleEnd(i)">
                    </span>
                </transition-group>
            </router-link>
        </div>

        <div class="mobile-navbar-divider"></div>
    </div>
</template>

<script setup>
import Hamburger from "./Hamburger";
import MobileLogo from "../../logo/Mobile";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

const route = useRoute();
const store = useStore();
const notComingSoonView = computed(() => route.name !== "coming-soon");
const notComingSoonViewConnectClass = computed(() => (route.name === "coming-soon" ? "flex-end" : ""));
const notComingSoonViewLogoClass = computed(() => (route.name === "coming-soon" ? "coming-soon-logo-pos" : ""));

function toggleNavbar() {
    store.dispatch("setIsFloatingNavOpen", !store.state.isFloatingNavOpen);
}

function closeNavbar() {
    if (store.state.isFloatingNavOpen) store.dispatch("setIsFloatingNavOpen", false);
}

// buttons
const ripples = ref([]);
const mvBtn = ref();

function animateRipple(e) {
    const el = mvBtn.value;
    if (el === null) return;
    const pos = el.getBoundingClientRect();

    ripples.value.push({
        x: e.clientX - pos.left,
        y: e.clientY - pos.top,
        show: true,
    });
}

function rippleEnd(i) {
    this.ripples[i].show = false;
}
</script>

<style lang="scss" scoped>
.mobile-navbar-wrapper {
    z-index: 10;
    width: 100%;
    height: 80px;
    display: flex;
    flex-flow: row;
    position: relative;
    justify-content: space-between;
}

.hamburger-wrapper {
    margin: 5px;
    margin-top: 24px;
    margin-left: 36px;
}

.mobile-logo {
    left: 50%;
    position: absolute;
    transform: translateX(-50%) translate(-5px);
    margin-top: 21px !important;
}

.coming-soon-logo-pos {
    left: 5.5%;
    margin-top: 25px !important;
    transform: translateX(-6%);

    @media (min-width: 600px) {
        left: 3%;
        transform: translateX(-3%);
    }
}

.login-btn-wrapper {
    width: 57px;
    height: 34px;
    font-size: 14px;
    text-align: center;
    border: 1px solid #fff;
    padding: 6px 4px 10px 5px;
    font-family: "EBGaramond";
    outline: 0;
    overflow: hidden;
    position: relative;
    display: inline-block;
    margin: 17px 24px 10px 28px;

    a {
        color: #fff;
        text-decoration: none;
    }
}

.mobile-navbar-divider {
    left: 0;
    bottom: 18px;
    height: 20px;
    margin: auto 16px;
    position: absolute;
    width: calc(100% - 32px);
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
}

@media (min-width: 1025px) {
    .mobile-navbar-wrapper {
        display: none !important;
    }
}
</style>
