<template>
    <div class="mobile-logo">
        <router-link :to="{ name: 'home' }">
            <img src="/assets/mobile-logo.svg" alt="MVD" />
        </router-link>
    </div>
</template>

<style lang="scss" scoped>
.mobile-logo {
    display: flex;
    display: block;
    align-items: center;
    justify-content: center;

    * {
        color: #fff;
        font-size: 24px;
    }
}
</style>
