export const onlyNumbers = (string) => {
    return string.replace(/[^0-9]/g, "");
};

export const firstCapitalLetter = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
};

export const numberWithCommas = (number) => {
    const splittedNumber = number.toString().split(".");

    if (splittedNumber[0] && splittedNumber[1]) {
        splittedNumber[0] = splittedNumber[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        return splittedNumber[0] + "." + splittedNumber[1];
    } else if (splittedNumber[0]) return splittedNumber[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getQueryStringFromObj = (obj) => {
    let counter = 0;
    const objKeys = Object.keys(obj);
    let returnStr = objKeys.length ? "?" : "";

    for (let key in objKeys) {
        counter++;

        if (counter === objKeys.length) returnStr += `${objKeys[key]}=${obj[objKeys[key]]}`;
        else returnStr += `${objKeys[key]}=${obj[objKeys[key]]}&`;
    }

    return returnStr;
};
