<template>
    <nav class="nav-links-wrapper">
        <router-link :to="{ name: 'home' }">Home</router-link>
        <router-link :to="{ name: 'products' }">Diamonds</router-link>
        <router-link :to="{ name: 'quality' }">Quality</router-link>
        <router-link :to="{ name: 'coming-soon' }">Claim Yours</router-link>
    </nav>
</template>

<style lang="scss" scoped>
.nav-links-wrapper {
    width: 50vw;
    display: flex;
    flex-flow: row;
    font-size: 18px;
    font-family: "EBGaramond";
    align-self: center;
    margin-top: 6px;
    z-index: 999;
    position: relative;
    justify-content: space-between;

    a {
        color: #fff;
        text-decoration: none;

        @media (min-width: 1025px) {
            width: 25%;
            position: relative;
            text-align: center;
        }
    }
}
</style>
