<template>
    <div :class="['floating-navbar-wrapper', openAnimated]">
        <router-link :to="{ name: 'home' }" @click="closeNav">Home</router-link>
        <hr class="divider" />
        <router-link :to="{ name: 'products' }" @click="closeNav">Diamonds</router-link>
        <hr class="divider active-divider" />
        <router-link :to="{ name: 'quality' }" @click="closeNav">Quality</router-link>
        <hr class="divider" />
        <router-link :to="{ name: 'coming-soon' }" @click="closeNav">Claim Yours</router-link>
    </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

const openAnimated = computed(() => (!store.state.isFloatingNavOpen ? "close-animation-class" : ""));

function closeNav() {
    store.dispatch("setIsFloatingNavOpen", false);
}
</script>

<style lang="scss" scoped>
@media (max-width: 1024px) {
    .close-animation-class {
        width: 0 !important;
        opacity: 0 !important;
    }
}

.floating-navbar-wrapper {
    left: 0;
    top: 80px;
    width: 100%;
    opacity: 1;
    font-size: 18px;
    transition: 0.3s;
    padding-top: 50%;
    z-index: 9999999;
    position: fixed;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-stretch: normal;
    letter-spacing: normal;
    overflow: hidden !important;
    min-height: calc(100% - 80px);
    font-family: "EBGaramond";
    background-color: $carbon-700 !important;

    @media (min-width: 1025px) {
        display: none !important;
    }

    hr {
        width: 32px;
        display: block;
        margin: 32px auto;
        border-color: #fff;
    }

    a {
        color: #fff;
        text-decoration: none;
    }
}
</style>
