<template>
    <div class="desktop-navbar-wrapper">
        <div class="desktop-logo-wrapper">
            <desktop-logo />
        </div>
        <div class="dividers-wrapper">
            <div :class="[notComingSoonViewDivider, 'desktop-navbar-divider', 'left-divider']"></div>
            <div class="desktop-navbar-divider right-divider"></div>
        </div>
        <links class="links-wrapper" v-if="notComingSoonView" />
        <div class="login-btn-wrapper btn-secondary" ref="mvBtn" @click="animateRipple">
            <router-link :to="{ name: 'coming-soon' }">
                Connect Wallet
                <transition-group>
                    <span
                        class="ripple"
                        :ref="'ripple-' + i"
                        :key="'ripple' + i"
                        v-for="(val, i) in ripples"
                        :style="{ top: val.y + 'px', left: val.x + 'px' }"
                        v-on:animationend="rippleEnd(i)">
                    </span>
                </transition-group>
            </router-link>
        </div>
    </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useRoute } from "vue-router";
import Links from "./Links";
import DesktopLogo from "../../logo/Desktop";

const route = useRoute();
const notComingSoonView = computed(() => route.name !== "coming-soon");
const notComingSoonViewDivider = computed(() => (route.name === "coming-soon" ? "no-right-border" : ""));

// buttons
const ripples = ref([]);
const mvBtn = ref();

function animateRipple(e) {
    const el = mvBtn.value;
    if (el === null) return;
    const pos = el.getBoundingClientRect();

    ripples.value.push({
        x: e.clientX - pos.left,
        y: e.clientY - pos.top,
        show: true,
    });
}

function rippleEnd(i) {
    this.ripples[i].show = false;
}
</script>

<style lang="scss" scoped>
.desktop-navbar-wrapper {
    z-index: 10;
    width: calc(100vw - 112px);
    height: 80px;
    display: flex;
    margin: 0 auto;
    flex-flow: row;
    position: relative;
    justify-content: space-between;
    @media (max-width: 1024px) {
        display: none !important;
    }
    .desktop-logo-wrapper {
        margin-top: 21px;
        margin-left: 24px;
    }
    .links-wrapper {
        left: 50%;
        z-index: 99;
        position: absolute;
        transform: translate(-51%, 0);
    }
    .login-btn-wrapper {
        outline: 0;
        overflow: hidden;
        position: relative;
        display: inline-block;
        width: 100px;
        height: 34px;
        font-size: 14px;
        text-align: center;
        padding: 6px 4px 10px 5px;
        font-family: "EBGaramond";
        border: 1px solid #fff;
        margin: 18px 24px 28px 28px;

        a {
            color: #fff;
            text-decoration: none;
        }
    }
    .dividers-wrapper {
        left: 0;
        bottom: 13px;
        z-index: 99;
        width: calc(100vw - 112px);
        display: flex;
        flex-wrap: nowrap;
        position: absolute;
        align-items: center;
        left: 50%;
        transform: translate(-50%, 0);
        justify-content: space-between;

        div {
            width: 50%;
            z-index: 99;
            position: relative;
        }
    }
    .desktop-navbar-divider {
        bottom: 5px;
        height: 20px;
        position: absolute;
        border-left: 1px solid #fff;
        border-right: 1px solid #fff;
        border-bottom: 1px solid #fff;
    }
    .right-divider {
        border-left: 0 !important;
    }
}
</style>
