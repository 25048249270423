import Meta from 'quasar/src/plugins/Meta.js';;
import "./styles/quasar.scss";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/fontawesome-v5/fontawesome-v5.css";

// To be used on app.use(Quasar, { ... })
export default {
    config: {},
    plugins: {
        Meta,
    },
};
