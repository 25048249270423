<template>
    <div class="desktop-logo">
        <router-link :to="{ name: 'home' }">
            <img src="/assets/desktop-logo.svg" alt="METAVERSE DIAMONDS" />
        </router-link>
    </div>
</template>

<style lang="scss" scoped>
.desktop-logo {
    display: flex;
    justify-content: center;
    align-items: center;
}
* {
    color: #fff;
    font-size: 24px;
}
</style>
