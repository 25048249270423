<template>
    <header v-if="!qualitySubPage">
        <mobile-navbar />
        <desktop-navbar />
    </header>
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import MobileNavbar from "./components/Mobile";
import DesktopNavbar from "./components/Desktop";

const route = useRoute();
const qualitySubPage = computed(() => (route.name === "sub-quality" && window.innerWidth <= 1024 ? "dis-non" : ""));
</script>

<style lang="scss" scoped>
header {
    top: 0;
    width: 100%;
    z-index: 9999999;
    position: fixed;
    background: $carbon-500;
}
</style>
